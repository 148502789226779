import { BuildFilled } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useState } from 'react';
import './active-freight.css';

export default function ActiveFreightDrawer({ visible }) {
  const [freightOpened, setFreightOpened] = useState(true);
  const hasActiveFreight = true;

  return hasActiveFreight && (
    <Drawer
      placement="bottom"
      visible={visible}
      closable={false}
      mask={freightOpened}
      height={freightOpened ? 260 : 60}
      title={(
        <>
          <BuildFilled className="" />
          {' '}
          بار فعال شما
        </>
            )}
      onClick={() => { if (!freightOpened) setFreightOpened(true); }}
      onClose={() => { if (freightOpened) setFreightOpened(false); }}
    >
      {freightOpened && (
      <>
        مبدا: رشت
        <br />
        مقصد: تهران

      </>
      )}
    </Drawer>
  );
}
