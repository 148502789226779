function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default function Currency({ value, currency = 'تومان' }) {
  if (!value) return <></>;
  return (
    <>
      {numberWithCommas(value)}
      {' '}
      {currency}
    </>
  );
}
