import api from './api';

export async function sendAuthCode(phone, token) {
  const body = new FormData();
  body.append('phone', phone);
  body.append('token', token);
  return (await api.post('/auth/code', body)).data.data;
}

export async function driverLogin(username, passCode) {
  const body = new FormData();
  body.append('sessionId', username);
  body.append('code', passCode);
  return (await api.post('/auth/verify', body)).data;
}

export async function checkAuth() {
  return (await api.get('/users/me')).data.data;
}
