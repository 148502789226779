import { TreeSelect } from 'antd';
import useData from 'hooks/useData';
import { destCities } from 'services/base';

export default function DestCitySelect({ value, onChange = undefined, disabled }) {
  const { data: dest } = useData(destCities, true);
  return (
    <TreeSelect
      value={value}
      onChange={onChange}
      disabled={disabled}
      treeData={dest}
      showSearch
      allowClear
      filterTreeNode={(input, treeNode) => (
        treeNode.value < 1000000 && treeNode.title.includes(input))}
    />

  );
}
