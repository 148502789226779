import { MessageOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';

export default function DriverDetail({ driverId }) {
  return (
    <Card
      title="راننده"
      actions={[
        <PhoneOutlined />,
        <MessageOutlined />,
      ]}
    >
      <Card.Meta
        avatar={<Avatar size={50} icon={<UserOutlined />} />}
        title="شهریار کبیری"
        description={`کد راننده: ${driverId}`}
      />
    </Card>
  );
}
