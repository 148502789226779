import {
  Button, Card, Form, Input,
} from 'antd';

export default function DriverVerify() {
  const [form] = Form.useForm();
  const submit = () => {
    form.validateFields()
      .then(() => {
      })
      .catch(() => {
      });
  };
  return (
    <Card title="تایید کارت هوشمند">
      <Form form={form} onFinish={submit}>
        <Form.Item label="شماره موبایل">
          <Input disabled value="09120001110" />
        </Form.Item>
        <Form.Item
          name="nationalCode"
          label="کد ملی"
          rules={[{ required: true, message: 'کد ملی ضروریست' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="smartCardNo"
          label="شماره کارت هوشمند رانندگان"
          rules={[{ required: true, message: 'شماره کارت هوشمند ضروریست' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">تایید اطلاعات</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
