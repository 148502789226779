import { Table } from 'antd';
import OrderStatus from 'components/OrderStatus';
import useData from 'hooks/useData';
import { useHistory } from 'react-router-dom';
import OrderService from 'services/OrderService';

/**
 * @type {import('antd/lib/table').ColumnsType<any>}
 */
const columns = [
  {
    title: 'وضعیت',
    dataIndex: 'status',
    render: (d) => <OrderStatus status={d} />,
  },
  {
    title: 'مبدا',
    dataIndex: 'sourceCity',
  },
  {
    title: 'مقضد',
    dataIndex: 'destinationCity',
  },
  {
    title: 'وسیله',
    dataIndex: 'vehicleType',
  },
];

export default function MyOrders() {
  const { data, loading } = useData(OrderService.myOrders, true);
  const history = useHistory();
  const gotoOrder = (id) => history.push(`/order/${id}`);
  const items = data?.items;
  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={items}
      onRow={(record) => ({ onClick: () => gotoOrder(record.id) })}
    />
  );
}
