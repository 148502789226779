export default function Weight({ value }) {
  if (!value) { return (<>نامشخص</>); }
  return (
    <>
      {value}
      {' '}
      کیلوگرم
    </>
  );
}
