import {
  Button,
  Card, Form, Input, InputNumber,
} from 'antd';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function FreightStep2({ updateFreight }) {
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ price: 600000 });
  }, []);
  const gotoNext = async () => {
    const vals = await form.validateFields();
    updateFreight(vals);
    history.push('step3');
  };

  return (
    <Card title="پیشنهاد قیمت">
      <Form form={form}>
        <Form.Item label="قیمت پیشنهادی">
          <Input disabled value="۶۰۰،۰۰۰ تومان" />
        </Form.Item>
        <Form.Item name="price" label="قیمت شما">
          <InputNumber
            placeholder="وزن بار (کیلوگرم)"
            min={100000}
            max={10000000}
            step={25000}
            formatter={(value) => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            مرحله بعد

          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
