import { useState, createContext } from 'react';

const SearchContext = createContext({ filter: null, setFilter: null });

export function SearchContextProvider({ children }) {
  const [filter, setFilter] = useState(null);
  return (
    <SearchContext.Provider value={{ filter, setFilter }}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContext;
