import { CloseSquareFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import SearchContext from 'components/SearchContext';
import VehicleTypeView from 'components/VehicleTypeView';
import useData from 'hooks/useData';
import { useContext, useEffect } from 'react';
import { destCityOf, srcCityOf } from 'services/base';

export default function SearchTagBar() {
  const { filter, setFilter } = useContext(SearchContext);
  const { data: sourceCity, load: getSourceCity } = useData(srcCityOf);
  const { data: destCity, load: getDestCity } = useData(destCityOf);
  useEffect(() => {
    getSourceCity(filter?.sourceCity);
  }, [filter?.sourceCity]);

  useEffect(() => {
    getDestCity(filter?.destinationCity);
  }, [filter?.destinationCity]);

  const closeIcon = <CloseSquareFilled style={{ fontSize: '1.4em' }} />;
  let tags = [];
  if (filter?.vehicleType) {
    tags = tags.concat(
      <Tag
        key="vehicleType"
        closable
        closeIcon={closeIcon}
        onClose={() => setFilter({ ...filter, vehicleType: null })}
      >
        وسیله:
        {' '}
        <VehicleTypeView vehicleType={filter?.vehicleType} />
      </Tag>,
    );
  }
  if (sourceCity) {
    tags = tags.concat(
      <Tag
        key="sourceCity"
        closable
        closeIcon={closeIcon}
        onClose={() => setFilter({ ...filter, sourceCity: null })}
      >
        مبدا:
        {' '}
        {sourceCity.title}
      </Tag>,
    );
  }
  if (destCity) {
    tags = tags.concat(
      <Tag
        key="destinationCity"
        closable
        closeIcon={closeIcon}
        onClose={() => setFilter({ ...filter, destinationCity: null })}
      >
        مقصد:
        {' '}
        {destCity.title}
      </Tag>,
    );
  }
  if (tags.length > 0) {
    return <>{tags}</>;
  }
  return (
    <></>
  );
}
