import { Col, Image, Row } from 'antd';

export default function AuthLayout({ children }) {
  return (
    <>
      <Row>
        <Col>
          <Image src="/auth-header.png" />
        </Col>

      </Row>
      <Row align="middle">
        <Col span={24} flex={1}>
          {children}
        </Col>
      </Row>
    </>
  );
}
