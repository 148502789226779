import useData from 'hooks/useData';
import {
  Button, Card, Col, Dropdown, Menu, Row,
} from 'antd';
import {
  useContext, useState, useCallback, useEffect,
} from 'react';
import SearchContext from 'components/SearchContext';
import OrderService from 'services/OrderService';
import { SortAscendingOutlined } from '@ant-design/icons';
import RequestForm from './RequestForm';
import MatchedOrders from './MatchedOrders';
import SearchTagBar from './SearchTagBar';

const sortOptions = [
  { value: 'id,desc', title: 'جدیدترین' },
  { value: 'weight,asc', title: 'سبک ترین' },
  { value: 'price.driverPrice,desc', title: 'گرانترین' },
];
const timeSlots = [
  { tab: 'امروز', key: '1' },
  { tab: 'فردا', key: '2' },
  { tab: 'پس‌فردا', key: '3' },
];
export default function RequestFreight() {
  const { filter, setFilter } = useContext(SearchContext);
  const [searchOpen, setSearchOpen] = useState(false);
  const { data: orders, load, loading } = useData(OrderService.myOrders);
  const [selectedSort, setSelectedSort] = useState(sortOptions[0].value);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(timeSlots[0].key);

  const search = useCallback((req) => {
    setSearchOpen(false);
    setFilter(req);
  }, [setSearchOpen, setFilter]);

  useEffect(() => {
    load({ ...filter, time: selectedTimeSlot, sort: selectedSort });
  },
  [filter, selectedSort, selectedTimeSlot, load]);

  const sortTitle = sortOptions.find((s) => s.value === selectedSort)?.title;

  const sortMenu = (
    <Menu>
      {sortOptions.map((s) => (
        <Menu.Item onClick={() => setSelectedSort(s.value)} key={s.value}>{s.title}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Card
        title={(
          <Row>
            <Col span={6}>
              <Button
                block
                onClick={() => { if (!searchOpen) setSearchOpen(true); }}
                type={searchOpen ? 'text' : 'default'}
              >
                جستجو

              </Button>
            </Col>
            <Col span={8} push={10}>
              <Dropdown overlay={sortMenu}>
                <Button block icon={<SortAscendingOutlined />}>{sortTitle}</Button>
              </Dropdown>
            </Col>
          </Row>
        )}
        size="small"
      >
        {!searchOpen && <SearchTagBar />}
        {searchOpen && <RequestForm saveRequest={search} />}
      </Card>
      <Card
        title="بارها"
        tabList={timeSlots}
        activeTabKey={selectedTimeSlot}
        onTabChange={(key) => setSelectedTimeSlot(key)}
        size="small"
      >
        <MatchedOrders orders={orders} loading={loading} />
      </Card>
    </>
  );
}
