import DataSelect from 'components/DataSelect';
import { vechicleTypes } from 'services/base';

export default function VehicleSelect({ value, onChange = undefined, disabled = false }) {
  return (
    <DataSelect
      value={value}
      onChange={onChange}
      disabled={disabled}
      fetchItems={vechicleTypes}
      placeholder="وسیله نقلیه (را انتخاب کنید)"
    />
  );
}
