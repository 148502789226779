import {
  Button,
  Card, Form, Input,
} from 'antd';
import SrcCitySelect from 'components/SrcCitySelect';
import { useHistory } from 'react-router-dom';

export default function FreightStep4({ freight, updateFreight }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const gotoNext = () => {
    updateFreight(form.getFieldsValue());
    history.push('step5');
  };

  return (
    <Card title="محل دریافت بار">
      <Form form={form}>
        <Form.Item label="شهر">
          <SrcCitySelect disabled value={freight.sourceCity} />
        </Form.Item>
        <Form.Item name="sourceAddress" label="آدرس">
          <Input.TextArea placeholder="لطفا آدرس را به صورت دقیق وارد کنید" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            مرحله بعد
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
