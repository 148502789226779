import {
  Avatar, Col, Row, Layout, Button,
} from 'antd';
import {
  ArrowRightOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from 'components/AuthContext';
import ActiveFreightDrawer from './ActiveFreightDrawer';

export default function DriverAppLayout({ children }) {
  const history = useHistory();
  const location = useLocation();
  const gotoHome = () => history.push('/');
  const gotoProfile = () => history.push('/profile');
  const { user } = useContext(AuthContext);

  const isHome = location.pathname === '/';
  const showCurrentFreight = isHome && user?.driver?.activeReservationId;

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Layout.Header>
        {isHome
          && (
            <Row>
              <Col span={3}><img src="/logo1.png" alt="" /></Col>
              <Col span={3} offset={18}>
                <Avatar icon={<UserOutlined onClick={gotoProfile} />} />
              </Col>
            </Row>
          )}
        {!isHome && (
          <Button
            type="text"
            style={{ color: 'white' }}
            icon={<ArrowRightOutlined color="white" />}
            onClick={gotoHome}
          />
        )}

      </Layout.Header>
      <Layout.Content style={{ paddingBottom: '46px' }}>
        {children}
      </Layout.Content>
      {/* <Layout.Footer style={{
        padding: '0px',
        overflow: 'hidden',
        position: 'fixed',
        bottom: '0px',
        width: '100%',
      }}
      >
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<HomeOutlined />} onClick={gotoHome}>خانه</Menu.Item>
          <Menu.Item key="2" icon={<BuildOutlined />} onClick={gotoOrder}>بارها</Menu.Item>
          <Menu.Item key="3" icon={<UserOutlined />} onClick={gotoProfile}>مشخصات</Menu.Item>
        </Menu>
      </Layout.Footer> */}
      <Layout.Footer>
        <ActiveFreightDrawer visible={showCurrentFreight} />
      </Layout.Footer>
    </Layout>
  );
}
