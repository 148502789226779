import { Layout, Menu } from 'antd';
import {
  ApartmentOutlined,
  BuildOutlined, HomeOutlined, LogoutOutlined, UsergroupAddOutlined, UserOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthUser from 'hooks/useAuthUser';
import { useEffect } from 'react';
import api from 'services/api';

export default function PanelAppLayout({ children }) {
  const {
    authUser, loading: authLoading, refreshAuth, isAdmin,
  } = useAuthUser();
  const history = useHistory();
  const location = useLocation();
  const shouldLoginRedirect = !authLoading && !authUser && !location.pathname.startsWith('/auth/');
  useEffect(() => {
    if (shouldLoginRedirect) {
      history.push('/auth/login');
    }
  }, [shouldLoginRedirect]);

  const gotoHome = () => history.push('/');
  const gotoOrder = () => history.push('/orders/');
  const gotoOrg = () => history.push('/organization/');
  const gotoUsers = () => history.push('/users/');
  const logout = () => api.post('/auth/logout')
    .then(() => { refreshAuth(); });
  return (
    <Layout>
      <Layout.Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<HomeOutlined />} onClick={gotoHome}>خانه</Menu.Item>
          <Menu.Item key="2" icon={<BuildOutlined />} onClick={gotoOrder}>بارها</Menu.Item>
          { isAdmin && <Menu.Item key="3" icon={<ApartmentOutlined />} onClick={gotoOrg}>سازمان‌ها</Menu.Item>}
          { isAdmin && <Menu.Item key="4" icon={<UsergroupAddOutlined />} onClick={gotoUsers}>کاربران</Menu.Item>}
          <Menu.SubMenu key="5" icon={<UserOutlined />} title={authUser?.username}>
            <Menu.Item icon={<LogoutOutlined />} onClick={logout}>خروج</Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Layout.Header>
      <Layout.Content>
        {children}
      </Layout.Content>
      <Layout.Footer>
        نیک آپ
      </Layout.Footer>
    </Layout>
  );
}
