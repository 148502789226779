import { TreeSelect } from 'antd';
import useData from 'hooks/useData';
import { sourceCities } from 'services/base';

export default function SrcCitySelect({ value, onChange = undefined, disabled = false }) {
  const { data: srcCities } = useData(sourceCities, true);
  return (
    <TreeSelect
      value={value}
      onChange={onChange}
      treeData={srcCities}
      showSearch
      allowClear
      disabled={disabled}
      filterTreeNode={(input, treeNode) => (
        treeNode.value < 1000000 && treeNode.title.includes(input))}
    />

  );
}
