import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://zanik.herokuapp.com/'
    : '/',
  timeout: 10000,
  withCredentials: true,
});

export default api;

export const fetcher = (url) => api.get(url).then((res) => res.data.data);
