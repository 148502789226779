import { Switch, Route } from 'react-router-dom';
import ChangePasswordForm from './ChangePasswordForm';
import LoginForm from './LoginForm';

export default function Auth() {
  return (
    <>
      <Switch>
        <Route path="/auth/change-pass">
          <ChangePasswordForm />
        </Route>
        <Route path="/auth/login">
          <LoginForm />
        </Route>
      </Switch>
    </>
  );
}
