import useAuthUser from 'hooks/useAuthUser';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import api from 'services/api';
import Auth from './Auth';

export default () => {
  const { authUser, refreshAuth, passwordResetRequired } = useAuthUser();
  const location = useLocation();
  const history = useHistory();
  const { pathname, search } = location;
  useEffect(() => {
    if (!authUser && pathname === '/auth/complete') {
      const qs = new URLSearchParams(search);
      const sessionId = qs.get('i');
      const code = qs.get('c');
      if (sessionId && code) {
        const params = new URLSearchParams({ sessionId, code });
        api.post('/auth/verify', params)
          .then((res) => res.data)
          .then((auth) => {
            if (auth.success) {
              refreshAuth();
            }
          });
      }
    }
  }, [authUser, search, passwordResetRequired]);

  useEffect(() => {
    if (passwordResetRequired && pathname !== '/auth/change-pass') {
      history.push('/auth/change-pass');
    }
  }, [passwordResetRequired, pathname]);
  useEffect(() => {
    if (authUser && !passwordResetRequired) {
      history.push('/');
    }
  }, [authUser, passwordResetRequired]);

  return (
    <Auth />
  );
};
