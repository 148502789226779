import {
  Button,
  Form,
} from 'antd';
import DestCitySelect from 'components/DestCitySelect';
import SearchContext from 'components/SearchContext';
import SrcCitySelect from 'components/SrcCitySelect';
import VehicleTypeSelect from 'components/VehicleTypeSelect';
import { useContext } from 'react';

export default function RequestForm({ saveRequest }) {
  const [form] = Form.useForm();
  const { filter } = useContext(SearchContext);

  const gotoNext = async () => {
    const vals = await form.validateFields();
    saveRequest(vals);
  };

  return (
    <Form form={form}>
      <Form.Item
        key="vehicleType"
        name="vehicleType"
        label="وسیله نقلیه"
        initialValue={filter?.vehicleType}
        rules={[
          { required: false },
        ]}
      >
        <VehicleTypeSelect />
      </Form.Item>
      <Form.Item
        key="sourceCity"
        name="sourceCity"
        label="مبدأ"
        initialValue={filter?.sourceCity}
        rules={[
          { required: false },
        ]}
      >
        <SrcCitySelect />
      </Form.Item>
      <Form.Item
        key="destinationCity"
        name="destinationCity"
        label="مقصد"
        initialValue={filter?.destinationCity}
        rules={[
          { required: false },
        ]}
      >
        <DestCitySelect />
      </Form.Item>
      <Form.Item key="search">
        <Button
          type="primary"
          block
          onClick={gotoNext}
        >
          جستجو
        </Button>
      </Form.Item>
    </Form>
  );
}
