import useData from 'hooks/useData';
import { createContext } from 'react';
import { checkAuth } from 'services/AuthService';

const AuthContext = createContext({
  loggedIn: false,
  loading: false,
  user: null,
  checkAuth: () => { },
});

export default AuthContext;

export const AuthConsumer = AuthContext.Consumer;

export function AuthProvider({ children }) {
  const {
    data: user, load, loading, error,
  } = useData(checkAuth, true);

  const loggedIn = !loading && !error;
  return (
    <AuthContext.Provider value={{
      loggedIn, loading, user, checkAuth: load,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}
