import DriverApp from 'screens/driver/DriverApp';
import PanelApp from 'screens/panel/PanelApp';

function App() {
  const app = process.env.REACT_APP_TARGET === 'panel' ? 'panel' : 'driver';
  if (app === 'driver') {
    return (
      <DriverApp />
    );
  }
  return <PanelApp />;
}

export default App;
