import {
  Button, Card, Descriptions, Spin,
} from 'antd';
import DriverDetail from 'components/DriverDetail';
import OrderStatus from 'components/OrderStatus';
import VehicleTypeSelect from 'components/VehicleTypeSelect';
import useData from 'hooks/useData';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import OrderService, { OrderState } from 'services/OrderService';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function OrderDetail({ orderId }) {
  const fetchOrder = useCallback(() => OrderService.findById(orderId), [orderId]);
  const {
    loading, load, data: freight, error,
  } = useData(fetchOrder, true);
  const query = useQuery();

  const matchOrder = async () => {
    await OrderService.matchOrder(orderId);
    load();
  };
  const forDriver = !!query.get('driver');
  const waiting = freight && freight.status === OrderState.WAITING_MATCH;

  if (loading) { return <Spin />; }
  if (error) {
    return <>Error</>;
  }

  return (
    <>
      <Card title={`شناسه ارسال ${freight.id}`}>
        <Descriptions
          bordered
          extra={
            forDriver && waiting && <Button type="default" onClick={matchOrder}>اختصاص به من</Button>
     }
        >
          <Descriptions.Item label="وضعیت">
            <OrderStatus status={freight.status} />
          </Descriptions.Item>
          {waiting && (
          <Descriptions.Item label="معتبر تا">
            ۲ آذر ساعت ۱۳:۰۰ (یک ساعت دیگر)
          </Descriptions.Item>
          )}
          <Descriptions.Item label="وسیله">
            <VehicleTypeSelect value={freight.vehicleType} disabled />
          </Descriptions.Item>
          <Descriptions.Item label="مبدا">
            {freight.sourceCity}
          </Descriptions.Item>
          <Descriptions.Item label="مقصد">
            {freight.destinationCity}
          </Descriptions.Item>
          <Descriptions.Item label="هزینه">
            {`${freight.price} تومان`}
          </Descriptions.Item>
          <Descriptions.Item label="زمان ثبت">
            ۲۰ دقیقه پیش
          </Descriptions.Item>
        </Descriptions>
      </Card>
      {freight.status !== OrderState.WAITING_MATCH
      && <DriverDetail driverId={3001} />}
    </>
  );
}
