import { UserOutlined } from '@ant-design/icons';
import {
  Button, Card, Form, Input,
} from 'antd';
import useAuthUser from 'hooks/useAuthUser';
import api from 'services/api';

export default function LoginForm() {
  const [form] = Form.useForm();
  const { refreshAuth } = useAuthUser();
  const login = () => {
    form.validateFields()
      .then((vals) => api.post('/auth/login', new URLSearchParams(vals)))
      .then(() => refreshAuth());
  };
  return (
    <Card cover={<UserOutlined style={{ fontSize: '50px', color: '#1D3557' }} />}>
      <Form form={form} onFinish={login} layout="vertical">
        <Form.Item name="username" label="نام کاربری">
          <Input style={{ direction: 'ltr' }} />
        </Form.Item>
        <Form.Item name="password" label="گذرواژه">
          <Input.Password style={{ direction: 'ltr' }} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={false}
            loading={false}
            style={{ borderRadius: '5px' }}
            size="large"
          >
            ورود
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
