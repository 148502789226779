import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, Modal, Table,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';

function AddDialog({
  visible, closeAddDialog, addCallback, columns,
}) {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const addDialogCallback = () => {
    setSubmitting(true);
    return form.validateFields()
      .then((fields) => addCallback(fields))
      .then(closeAddDialog)
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Modal
        visible={visible}
        title="افزودن"
        onCancel={closeAddDialog}
        onOk={addDialogCallback}
        confirmLoading={submitting}
      >
        <Form form={form}>
          {columns
            .filter((col) => col.add !== false)
            .map((col) => (
              <Form.Item
                name={col.key || col.dataIndex}
                label={col.title}
                rules={[{ required: true }]}
              >
                {col.input ? col.input(col) : <Input />}
              </Form.Item>
            ))}
        </Form>
      </Modal>
    </>
  );
}

export default function DataTable({ columns, fetchData, addCallback }) {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [data, setData] = useState(null);
  const refreshData = useCallback(() => {
    if (typeof fetchData === 'function') {
      fetchData()
        .then((d) => {
          setData(d.items);
        });
    }
  }, [fetchData, setData]);
  const addCallbackInternal = (...args) => addCallback(...args)
    .then((d) => {
      refreshData();
      return d;
    });
  useEffect(() => {
    refreshData();
  }, []);

  const addButtonCallback = () => { setShowAddDialog(true); };
  const closeAddDialog = () => { setShowAddDialog(false); };
  const hasAddButton = !!addCallback;

  return (
    <>
      {hasAddButton && (
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={addButtonCallback}
        >
          افزودن
        </Button>
      )}
      <Table dataSource={data} columns={columns} />
      <AddDialog
        addCallback={addCallbackInternal}
        closeAddDialog={closeAddDialog}
        visible={showAddDialog}
        columns={columns}
      />
    </>
  );
}
