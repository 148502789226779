import {
  Button,
  Card, Form, InputNumber,
} from 'antd';
import DestCitySelect from 'components/DestCitySelect';
import SrcCitySelect from 'components/SrcCitySelect';
import VehicleSelect from 'components/VehicleSelect';
import { useHistory } from 'react-router-dom';

export default function FreightStep1({ updateFreight }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const gotoNext = async () => {
    const vals = await form.validateFields();
    updateFreight(vals);
    history.push('step2');
  };
  return (
    <Card title="مشخصات اولیه بار">
      <Form form={form}>
        <Form.Item
          name="weight"
          label="وزن بار"
          rules={[
            { required: true },
          ]}
        >
          <InputNumber
            placeholder="وزن بار (کیلوگرم)"
            min={100}
            max={30000}
            step={50}
            formatter={(value) => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="vehicleType"
          label="وسیله نقلیه"
          rules={[
            { required: true },
          ]}
        >
          <VehicleSelect />
        </Form.Item>
        <Form.Item
          name="sourceCity"
          label="مبدأ"
          rules={[
            { required: true },
          ]}
        >
          <SrcCitySelect />
        </Form.Item>
        <Form.Item
          name="destinationCity"
          label="مقصد"
          rules={[
            { required: true },
          ]}
        >
          <DestCitySelect />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            مرحله بعد

          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
