import {
  useCallback, useEffect, useRef, useState,
} from 'react';

/**
 *
 * @param {(...params)=>Promise<T>} fn
 * @returns {{data:T,loading:boolean, error:boolean, load:(params:U)=>void}}
 * @template T
 * @template U
 */
export default function useData(fn, initLoad = false) {
  const [{ loading, data, error }, setState] = useState(
    { loading: initLoad, data: undefined, error: false },
  );
  const callSeq = useRef({ seq: 0, resolved: 0 });
  const load = useCallback((...args) => {
    const currentSeq = callSeq.current.seq + 1;
    callSeq.current.seq = currentSeq;
    setState({ loading: true, error: false, data });
    fn(...args)
      .then((d) => {
        if (callSeq.current.resolved < currentSeq) {
          callSeq.current.resolved = currentSeq;
          setState({ loading: false, error: false, data: d });
        }
      })
      .catch(() => {
        if (callSeq.current.resolved < currentSeq) {
          callSeq.current.resolved = currentSeq;
        }
        setState({ loading: false, error: true, data });
      });
  }, [fn]);
  useEffect(() => {
    if (initLoad) {
      load();
    }
  }, [load, initLoad]);

  return {
    loading,
    load,
    data,
    error,
  };
}
