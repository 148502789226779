import {
  Button, Form, Typography, Row, Col,
} from 'antd';
import ReactCodeInput from 'react-verification-code-input';

export default function LoginCodeForm({
  submitting, onFinish, onChange, failed, codeKey,
  retransmitVerificationCode,
}) {
  const validateStatus = failed ? 'error' : null;

  return (
    <>
      <Typography>
        کد ۴ رقمی ارسال شده به شماره
        {codeKey}
        {' '}
        را وارد کنید.
      </Typography>
      <Form onFinish={onFinish} onChange={onChange}>
        <div style={{ direction: 'ltr', justifyContent: 'center', display: 'flex' }}>
          <Form.Item
            name="code"
            rules={[{ required: true }]}
            hasFeedback={failed}
            validateStatus={validateStatus}
            noStyle
          >
            <ReactCodeInput
              fields={4}
              fieldWidth={58}
              inputStyle={{
                fontFamily: 'monospace',
                margin: '4px',
                MozAppearance: 'textfield',
                width: '15px',
                borderRadius: '3px',
                fontSize: '14px',
                height: '26px',
                paddingLeft: '7px',
                backgroundColor: 'black !important',
                color: 'lightskyblue',
                border: '1px solid lightskyblue',
              }}
            />
          </Form.Item>
        </div>
        <Form.Item className="mb-1">
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={submitting}
            style={{ borderRadius: '5px' }}
            size="large"
          >
            ثبت کد
          </Button>
        </Form.Item>
      </Form>
      <Row>
        <Col className="f-16">
          کد را دریافت نکرده اید؟
          &nbsp;
          <Button type="link" onClick={retransmitVerificationCode}>ارسال مجدد کد</Button>
        </Col>
      </Row>
    </>
  );
}
