import {
  Button,
  Col, Empty, List, Row, Spin,
} from 'antd';
import { useHistory } from 'react-router-dom';
import FreightOutlined from 'components/FreightOutlined';
import { ArrowLeftOutlined, ExpandOutlined } from '@ant-design/icons';
import Weight from 'components/Weight';
import Currency from 'components/Currency';

/* eslint-disable no-nested-ternary */

export default function MatchedOrders({ orders, loading }) {
  const history = useHistory();
  const gotoOrder = (id) => history.push(`/order/${id}`);
  const matchedOrders = orders?.items;
  const noResult = !matchedOrders || matchedOrders.length === 0;

  return (
    <Spin spinning={loading} tip="در حال جستجو">
      {noResult && !loading ? (
        <Empty
          image="/generic_error.png"
          description="باری یافت نشد"
        />
      )
        : (
          <List
            itemLayout="vertical"
            dataSource={matchedOrders}
            split
            loadMore
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    block
                    icon={<ExpandOutlined />}
                    onClick={() => gotoOrder(item.id)}
                  >
                    جزییات
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<FreightOutlined size={120} />}
                  title={(
                    <Row>
                      <Col span={9}>
                        {`${item.srcProvince}: ${item.sourceCity}`}
                      </Col>
                      <Col span={6}><ArrowLeftOutlined /></Col>
                      <Col span={9}>
                        {`${item.destProvince}: ${item.destinationCity}`}
                      </Col>
                    </Row>
                    )}
                  description={(
                    <Row>
                      <Col span={12}>
                        {' '}
                        وزن بار:
                        {' '}
                        <Weight value={item.weight} />
                      </Col>
                      {' '}
                      <Col span={12}>
                        صافی:
                        {' '}
                        <Currency value={item.price} />
                      </Col>
                    </Row>
)}
                />
              </List.Item>
            )}
          />
        )}
    </Spin>
  );
}
