import CountUp from 'react-countup';
import classnames from 'classnames';
import styles from './Stat.module.less';

export default function DashboardCard({
  icon, title, number,
}) {
  return (
    <div className={classnames(styles.numberCard, styles.redCard)}>
      <span className={styles.iconWarp}>
        {icon}
      </span>
      <div className={styles.content}>
        <p className={styles.title}>{title || 'No Title'}</p>
        <p className={styles.number}>
          <CountUp
            start={0}
            end={number}
            duration={2.75}
            useEasing
            separator=","
          />
        </p>
      </div>
    </div>
  );
}
