import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import FreightFinal from './FreightFinal';
import FreightStep1 from './FreightStep1';
import FreightStep2 from './FreightStep2';
import FreightStep3 from './FreightStep3';
import FreightStep4 from './FreightStep4';
import FreightStep5 from './FreightStep5';

export default function RegisterFreight() {
  // eslint-disable-next-line
  const { step = '1' } = useParams();
  const freightRef = useRef();
  const updateFreight = (f) => {
    if (freightRef.current) {
      freightRef.current = {
        ...freightRef.current,
        ...f,
      };
    } else {
      freightRef.current = f;
    }
  };
  const freight = freightRef.current;
  switch (step) {
    case '1':
    default:
      return <FreightStep1 updateFreight={updateFreight} />;
    case '2':
      return <FreightStep2 updateFreight={updateFreight} />;
    case '3':
      return <FreightStep3 updateFreight={updateFreight} />;
    case '4':
      return <FreightStep4 freight={freight} updateFreight={updateFreight} />;
    case '5':
      return <FreightStep5 freight={freight} updateFreight={updateFreight} />;
    case '6':
      return <FreightFinal freight={freight} />;
  }
}
