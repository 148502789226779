import { Button, Form, Input } from 'antd';
import useRecaptcha from 'hooks/useRecaptcha';
import styles from './login.module.css';

const recapKey = '6Lef-UYaAAAAAOisty5RJXQTX7iHh5XNRrma__s4';

export default function LoginPhoneForm({
  submitting, onFinish,
}) {
  const recapRef = useRecaptcha(recapKey);
  const onFinish1 = (values) => {
    recapRef.current.then((grecaptcha) => {
      grecaptcha.execute(recapKey, { action: 'sendCode' })
        .then((token) => {
          if (onFinish) onFinish({ ...values, token });
        })
        .catch(() => {
          if (onFinish) onFinish({ ...values, token: null });
        });
    });
  };
  return (
    <Form onFinish={onFinish1}>
      <Form.Item
        label="شماره همراه"
        name="phone"
        rules={[
          { required: true, message: 'ورود شماره موبایل الزامی است' },
          { pattern: /^09[0-9]{9}$/, transform: (n) => n, message: 'لطفا یک شماره موبایل صحیح وارد نمایید.' },
        ]}
        className="no-red-star mb-36"
      >
        <Input className={styles.phoneInput} disabled={submitting} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          disabled={submitting}
          loading={submitting}
          style={{ borderRadius: '5px' }}
          size="large"
        >
          ارسال کد ورود
        </Button>
      </Form.Item>
    </Form>
  );
}
