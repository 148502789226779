import { useState, useEffect, useContext } from 'react';
import { driverLogin, sendAuthCode } from 'services/AuthService';
import useData from 'hooks/useData';
import AuthContext from 'components/AuthContext';
import LoginCodeForm from './LoginCodeForm';
import LoginPhoneForm from './LoginPhoneForm';

export default function LoginContainer() {
  const [step, setStep] = useState('phone');
  const {
    data: sessionData, load: sendCode, loading: codeLoading,
  } = useData(({ phone, token }) => sendAuthCode(phone, token));
  const {
    data: loginData, load: verifyCode, loading, error,
  } = useData(({ code }) => driverLogin(sessionData?.sessionId, code));

  useEffect(() => {
    if (sessionData?.sessionId) {
      setStep('code');
    }
  }, [sessionData]);

  const { checkAuth } = useContext(AuthContext);
  useEffect(() => {
    if (loginData?.success) {
      checkAuth();
    }
  }, [loginData]);

  if (step === 'phone') return <LoginPhoneForm onFinish={sendCode} submitting={codeLoading} />;
  return <LoginCodeForm onFinish={verifyCode} submitting={loading} failed={error} />;
}
