/**
 * @typedef {import('jalali-moment').Moment} Moment
 */

/**
 * @typedef FreightOrder
 * @property {string} id
 * @property {OrderState} status
 * @property {number} weight
 * @property {string} sourceCity
 * @property {string} sourceAddress
 * @property {string} destinationCity
 * @property {string} destinationAddress
 * @property {string} vehicleType
 * @property {number} price
 * @property {Moment} pickupDate
 * @property {string} timeFrom
 * @property {string} timeTo
 * @property {string} receiverName
 * @property {string} receiverMobile
 */

import api from './api';

/**
 * Enum for tri-state values.
 * @readonly
 * @enum {string}
 */
export const OrderState = {
  WAITING_MATCH: 'WAITING_MATCH',
  MATCHED: 'MATCHED',
};

const OrderService = {
  /**
   * @returns {Promise<FreightOrder[]>}
   */
  async myOrders(params) {
    return (await api.get('/shipping/search', { params })).data.data;
  },

  /**
   *
   * @param {FreightOrder} order
   */
  async addNewOrder(order) {
    return (await api.post('/shipping/', order)).data.data;
  },
  async findById(id) {
    return (await api.get(`/shipping/${id}`)).data.data;
  },
  async reserveInfo(id) {
    return (await api.get(`/shipping/${id}/reserve`)).data.data;
  },
  async matchOrder(id) {
    const order = await (await api.post(`/shipping/${id}/accept`)).data.data;
    return order;
  },
  async initPayment() {
    return (await api.post('/payment/init')).data.data;
  },
  async finalizePayment(authority) {
    return (await api.post('/payment/finalize', { authority })).data.data;
  },
};
export default OrderService;
