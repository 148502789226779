import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import {
  Alert, Avatar, Button, Card,
} from 'antd';
import AuthContext from 'components/AuthContext';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

export default function Profile() {
  const history = useHistory();
  const gotoDriverVerify = () => {
    history.push('/driver/verify');
  };
  const { user } = useContext(AuthContext);
  const driver = user?.driver;
  const driverVerified = driver?.verified;
  return (
    <>
      {!driverVerified && (
      <Alert
        type="warning"
        message="کارت هوشمند شما در سیستم ثبت نشده است. لطفا برای استفاده از تمام امکانات ابتدا مشخصات کارت هوشمند خودر را تایید کنید"
        banner
        showIcon={false}
        action={
          <Button onClick={gotoDriverVerify}>ثبت اطلاعات</Button>
        }
      />
      )}
      <Card
        style={{ width: '100%', padding: '24px' }}
        cover={(
          <Avatar
            size={120}
            icon={<UserOutlined />}
          />
)}
      >
        <Card.Meta
          title={(
            <>
              {driverVerified && <CheckCircleOutlined style={{ color: 'green' }} />}
              {driver?.name || driver?.mobileNo}
            </>
)}
          description="خاور ۶ تن"
        />
      </Card>
    </>
  );
}
