import {
  Button,
  Card, Form, Input,
} from 'antd';
import DestCitySelect from 'components/DestCitySelect';
import { useHistory } from 'react-router-dom';

export default function FreightStep5({ freight, updateFreight }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const gotoNext = () => {
    updateFreight(form.getFieldsValue());
    history.push('step6');
  };

  return (
    <Card title="مشخصات دریافت کننده">
      <Form form={form}>
        <Form.Item
          name="receiverName"
          label="نام و نام خانوادگی"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="receiverMobile"
          label="شماره همراه"
        >
          <Input />
        </Form.Item>

        <Form.Item label="شهر">
          <DestCitySelect disabled value={freight.destinationCity} />
        </Form.Item>
        <Form.Item name="destinationAddress" label="آدرس">
          <Input.TextArea placeholder="لطفا آدرس را به صورت دقیق وارد کنید" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            مرحله بعد
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
