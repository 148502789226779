import {
  Button, Card, Descriptions, notification, Spin,
} from 'antd';
import AuthContext from 'components/AuthContext';
import Currency from 'components/Currency';
import VehicleTypeView from 'components/VehicleTypeView';
import useData from 'hooks/useData';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import OrderService, { OrderState } from 'services/OrderService';

export default function OrderDetail({ orderId }) {
  const fetchOrder = useCallback(() => OrderService.findById(orderId), [orderId]);
  const { user } = useContext(AuthContext);
  const driverVerified = user?.driver?.verified;
  const {
    loading, data: freight, error,
  } = useData(fetchOrder, true);
  const history = useHistory();

  const gotoDriverVerify = () => {
    notification.close('notVerified');
    history.push('/driver/verify');
  };

  const gotoReserve = () => {
    if (driverVerified) {
      history.push(`/reserve/${orderId}`);
    } else {
      notification.open({
        message: 'خطا',
        description: 'برای رزرو بار لازم است ابتدا مشخصات خود را تکمیل کنید.',
        btn: <Button type="primary" onClick={gotoDriverVerify}>تکمیل ثبت‌نام</Button>,
        key: 'notVerified',
        duration: 0,
      });
    }
  };

  const waiting = freight && freight.status === OrderState.WAITING_MATCH;

  if (error) {
    return <>Error</>;
  }

  return (
    <>
      <Card title={`شناسه ارسال ${freight?.id || ''}`}>
        <Spin spinning={loading}>
          <Descriptions
            bordered
          >
            {waiting && (
            <Descriptions.Item label="معتبر تا">
              ۲ آذر ساعت ۱۳:۰۰ (یک ساعت دیگر)
            </Descriptions.Item>
            )}
            <Descriptions.Item label="وسیله">
              <VehicleTypeView vehicleType={freight?.vehicleType} />
            </Descriptions.Item>
            <Descriptions.Item label="نوع بار">
              {freight?.freightGroup}
            </Descriptions.Item>
            <Descriptions.Item label="مبدا">
              {freight?.sourceCity}
            </Descriptions.Item>
            <Descriptions.Item label="مقصد">
              {freight?.destinationCity}
            </Descriptions.Item>
            <Descriptions.Item label="صافی">
              <Currency value={freight?.price} />
            </Descriptions.Item>
            <Descriptions.Item label="موعد تحویل">
              شنبه ۸ تا ۱۲ ظهر
            </Descriptions.Item>
            <Descriptions.Item label="زمان ثبت">
              ۲۰ دقیقه پیش
            </Descriptions.Item>
            <Descriptions.Item label="توضیحات">
              تحویل ۴ بعد از ظهر به بعد
            </Descriptions.Item>
          </Descriptions>
        </Spin>
        <Button
          onClick={gotoReserve}
          block
          type="primary"
        >
          رزرو بار

        </Button>
      </Card>
    </>
  );
}
