import { Select, Spin } from 'antd';
import useData from 'hooks/useData';
import { vechicleTypes } from 'services/base';

export default function VehicleTypeSelect({ value, onChange = undefined, disabled = false }) {
  const { data: vehicles, loading } = useData(vechicleTypes, true);

  return (
    <Select
      placeholder="وسیله نقلیه (را انتخاب کنید)"
      value={value}
      onChange={onChange}
      notFoundContent={loading ? <Spin /> : null}
      disabled={disabled}
    >
      {vehicles?.map((v) => (
        <Select.Option key={v.id} value={v.id}>{v.title}</Select.Option>
      ))}
    </Select>
  );
}
