import { Select } from 'antd';
import { useEffect, useState } from 'react';

export default function DataSelect({
  onChange = undefined, value, fetchItems, placeholder = undefined, disabled = false,
}) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (typeof fetchItems === 'function') {
      fetchItems()
        .then((data) => data)
        .then((data) => setItems(data));
    }
  }, [fetchItems]);
  return (
    <Select placeholder={placeholder} onChange={onChange} value={value} disabled={disabled}>
      {items && items.map((item) => (
        <Select.Option value={item.id} key={item.id}>{item.title}</Select.Option>
      ))}
    </Select>
  );
}
