import { ConfigProvider, Spin } from 'antd';

// eslint-disable-next-line
import fa_IR from 'antd/lib/locale-provider/fa_IR';
import PanelAppLayout from 'components/PanelAppLayout';
import PanelAuthLayout from 'components/PanelAuthLayout';
import moment from 'jalali-moment';
import {
  Route, BrowserRouter as Router, Switch,
} from 'react-router-dom';
import useAuthUser from 'hooks/useAuthUser';
import Home from './Home';
import Login from './auth';
import MyOrders from './MyOrders';
import OrderDetail from './OrderDetail';
import RegisterFreight from './RegisterFreight';
import Organizations from './Organizations';
import Users from './Users';

moment.locale('fa');

export default function PanelApp() {
  const { loading: authLoading } = useAuthUser();
  return (
    // eslint-disable-next-line
    <ConfigProvider direction="rtl" locale={fa_IR}>
      {authLoading && <Spin />}
      {!authLoading && (
      <Router>
        <Switch>
          <Route path="/auth/">
            <PanelAuthLayout>
              <Login />
            </PanelAuthLayout>
          </Route>
          <Route path="/">
            <PanelAppLayout>
              <Switch>
                <Route
                  path="/order/:id"
                  render={({ match }) => <OrderDetail orderId={match.params.id} />}
                />
                <Route path="/orders/">
                  <MyOrders />
                </Route>
                <Route path="/organization/">
                  <Organizations />
                </Route>
                <Route path="/users/">
                  <Users />
                </Route>
                <Route path="/freight/step:step">
                  <RegisterFreight />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </PanelAppLayout>
          </Route>
        </Switch>
      </Router>
      )}
    </ConfigProvider>
  );
}
