import {
  Button,
  Card, Checkbox, Form, Input, InputNumber,
} from 'antd';
import DestCitySelect from 'components/DestCitySelect';
import SrcCitySelect from 'components/SrcCitySelect';
import VehicleSelect from 'components/VehicleSelect';
import { useHistory } from 'react-router-dom';
import OrderService from 'services/OrderService';
/**
 *
 * @param {{freight: import('services/OrderService').FreightOrder}} param0
 */
export default function FreightFinal({ freight }) {
  const history = useHistory();
  const gotoNext = async () => {
    const f = await OrderService.addNewOrder(freight);
    history.push(`/order/${f.id}`);
  };
  return (
    <>
      <Card title="مشخصات اولیه بار" bordered>
        <Form>
          <Form.Item label="وزن بار">
            <InputNumber
              disabled
              value={freight.weight}
              formatter={(value) => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item label="وسیله نقلیه">
            <VehicleSelect disabled value={freight.vehicleType} />
          </Form.Item>
          <Form.Item label="مبدأ">
            <SrcCitySelect disabled value={freight.sourceCity} />
          </Form.Item>
          <Form.Item label="مقصد">
            <DestCitySelect disabled value={freight.destinationCity} />
          </Form.Item>
        </Form>
      </Card>
      <Card title="پیشنهاد قیمت">
        <Form>
          <Form.Item label="قیمت ثبت شده">
            <Input disabled value={freight.price} />
          </Form.Item>
        </Form>
      </Card>
      <Card title="زمان دریافت بار">
        <Form>
          <Form.Item label="تاریخ">
            <Input disabled value={freight.pickupDate.format('YYYY-MM-DD')} />
          </Form.Item>
          <Form.Item label="از ساعت">
            <Input disabled value={freight.timeFrom} />
          </Form.Item>
          <Form.Item label="تا ساعت">
            <Input disabled value={freight.timeTo} />
          </Form.Item>
        </Form>
      </Card>
      <Card title="محل دریافت بار">
        <Form>
          <Form.Item label="شهر">
            <SrcCitySelect disabled value={freight.sourceCity} />
          </Form.Item>
          <Form.Item label="آدرس">
            <Input.TextArea disabled value={freight.sourceAddress} />
          </Form.Item>
        </Form>
      </Card>
      <Card title="مشخصات دریافت کننده">
        <Form>
          <Form.Item label="نام و نام خانوادگی">
            <Input disabled value={freight.receiverName} />
          </Form.Item>
          <Form.Item label="شماره همراه">
            <Input disabled value={freight.receiverMobile} />
          </Form.Item>

          <Form.Item label="شهر">
            <DestCitySelect disabled value={freight.destinationCity} />
          </Form.Item>
          <Form.Item label="آدرس">
            <Input.TextArea disabled value={freight.destinationAddress} />
          </Form.Item>
        </Form>
      </Card>
      <Form>
        <Form.Item>
          <Checkbox>شرایط ثبت بار را مطالعه کرده‌ام و با تمام قوانین و مفررات موافقم .</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            ثبت نهایی بار
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
