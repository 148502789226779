import {
  Button,
  Card, Form, TimePicker,
} from 'antd';
import { DatePicker } from 'components/JalaliPicker';
import { useHistory } from 'react-router-dom';

export default function FreightStep3({ updateFreight }) {
  const history = useHistory();
  const [form] = Form.useForm();

  const gotoNext = async () => {
    const vals = await form.validateFields();
    updateFreight(vals);
    history.push('step4');
  };
  return (
    <Card title="زمان دریافت بار">
      <Form form={form}>
        <Form.Item name="pickupDate" label="تاریخ">
          <DatePicker placeholder="انتخاب تاریخ" />
        </Form.Item>
        <Form.Item name="timeFrom" label="از ساعت">
          <TimePicker placeholder="ساعت شروع" format="HH:mm" minuteStep={15} />
        </Form.Item>
        <Form.Item name="timeTo" label="تا ساعت">
          <TimePicker placeholder="ساعت پایان" format="HH:mm" minuteStep={15} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            block
            onClick={gotoNext}
          >
            مرحله بعد

          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
