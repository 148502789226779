import { useEffect, useRef } from 'react';

const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = id;
    script.onload = () => {
      if (callback) { callback(); }
    };
    script.src = url;
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

/**
 *
 * @param {string} SITE_KEY
 * @returns {import('react').MutableRefObject<Promise<any>>}
 */
export default function useRecaptcha(SITE_KEY) {
  const reCapRef = useRef(null);
  useEffect(() => {
    let resolve;
    reCapRef.current = new Promise((r) => { resolve = r; });
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      () => {
        window.grecaptcha.ready(() => {
          resolve(window.grecaptcha);
        });
      });
  }, []);
  return reCapRef;
}
