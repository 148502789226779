import { CheckOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { OrderState } from 'services/OrderService';

/**
 *
 * @param {{status: OrderState}} status
 */
export default function OrderStatus({ status }) {
  switch (status) {
    case OrderState.WAITING_MATCH:
      return (
        <>
          <FieldTimeOutlined />
          منتظر یافتن وسیله
        </>
      );
    case OrderState.MATCHED:
      return (
        <>
          <CheckOutlined />
          اختصاص داده شده به راننده
        </>
      );
    default:
      return <></>;
  }
}
