/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'antd';
import useData from 'hooks/useData';
import { useEffect } from 'react';
import OrderService from 'services/OrderService';

export default function PaymentButton({ children, ...props }) {
  const { data, load, loading } = useData(OrderService.initPayment);
  useEffect(() => {
    if (data?.url) { window.open(data.url, '_blank'); }
  }, [data]);
  const startPayment = () => {
    load();
  };

  return (
    <Button
      block
      type="primary"
      {...props}
      loading={loading}
      onClick={startPayment}
    >
      {children}
    </Button>
  );
}
