import { CheckCircleTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';
import DataTable from 'components/DataTable';
import OrganizationSelect from 'components/OrganizationSelect';
import { useCallback, useState } from 'react';
import api from 'services/api';

const cols = [
  {
    title: 'شناسه',
    dataIndex: 'id',
    add: false,
  },
  {
    title: 'نام کاربری',
    dataIndex: 'username',
  },
  {
    title: 'نقش',
    dataIndex: 'role',
    add: false,
  },
  {
    title: 'سازمان',
    dataIndex: 'organizationId',
    input: () => (<OrganizationSelect />),
  },
];

const fetchData = () => api.get('/users/').then((resp) => resp.data.data);

const UserInviteLinkModal = ({ url, visible, close }) => (
  <Modal
    visible={visible}
    onOk={close}
    closable={false}
    cancelText={<></>}
    icon={<CheckCircleTwoTone />}
  >
    <p>کاربر با موفقیت ساخته شد.</p>
    <p>برای تکمیل و ورود لطفا لینک زیر را در اختیار خود کاربر قرار دهید.</p>
    <p>{url}</p>
  </Modal>
);

export default function Users() {
  const [inviteUrl, setInviteUrl] = useState(null);
  const addCallback = useCallback((fields) => api.post('/users/org-users/', fields)
    .then((resp) => resp.data.data)
    .then(({ url }) => {
      setInviteUrl(url);
    }),
  []);
  return (
    <>
      <DataTable columns={cols} fetchData={fetchData} addCallback={addCallback} />
      <UserInviteLinkModal
        visible={!!inviteUrl}
        url={inviteUrl}
        close={() => setInviteUrl(null)}
      />
    </>
  );
}
