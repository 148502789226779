import { Col, Row } from 'antd';

export default ({ children }) => (
  <Row>
    <Col
      xl={{ span: 8, offset: 8 }}
      md={{ span: 16, offset: 4 }}
      sm={{ span: 24, offset: 0 }}
      xs={{ span: 24, offset: 0 }}
    >
      {children}
    </Col>
  </Row>
);
