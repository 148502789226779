import { Button, Form, Input } from 'antd';
import useAuthUser from 'hooks/useAuthUser';
import { useState } from 'react';
import api from 'services/api';

export default () => {
  const { authUser, refreshAuth } = useAuthUser();
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = () => {
    form.validateFields()
      .then((vals) => {
        setSubmitting(true);
        return api.post('/auth/change-password', new URLSearchParams(vals));
      })
      .then(() => refreshAuth())
      .catch(() => setSubmitting(false));
  };
  const { username } = authUser;
  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        label="نام کاربری"
        name="username"
        initialValue={username}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="گذرواژه جدید"
        name="newPassword"
        rules={[
          { required: true, message: 'گذرواژه جدید را وارد کنید' },
        ]}
      >
        <Input.Password disabled={submitting} />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          disabled={submitting}
          loading={submitting}
          style={{ borderRadius: '5px' }}
          size="large"
        >
          تغییر گذرواژه
        </Button>
      </Form.Item>
    </Form>
  );
};
