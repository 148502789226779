import DataTable from 'components/DataTable';
import { useCallback } from 'react';
import api from 'services/api';

const cols = [
  {
    title: 'شناسه',
    dataIndex: 'id',
    add: false,
  },
  {
    title: 'نام',
    dataIndex: 'title',
  },
];

const fetchData = () => api.get('/organizations/')
  .then((resp) => resp.data.data);

export default function Organizations() {
  const addCallback = useCallback((fields) => api.post('/organizations/', fields), []);
  return <DataTable columns={cols} fetchData={fetchData} addCallback={addCallback} />;
}
