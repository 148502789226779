import api from './api';

function treeData(cities) {
  let pId = 1000001;
  return cities.reduce((res, city) => {
    if (res.length === 0 || res[res.length - 1].title !== city.province) {
      res.push({
        title: city.province, selectable: false, value: pId, children: [],
      });
      pId += 1;
    }
    const p = res[res.length - 1];
    p.children.push({ title: city.title, value: city.id });
    return res;
  }, []);
}
export async function sourceCities() {
  const cities = (await api.get('/offer/cities?source=true')).data.data.items;
  return treeData(cities);
}

export async function destCities() {
  const cities = (await api.get('/offer/cities?dest=true')).data.data.items;
  return treeData(cities);
}

export async function vechicleTypes() {
  return (await api.get('/offer/vehicles')).data.data.items;
}

export async function vehicleOf(id) {
  if (!id) { return null; }
  const vehicles = await vechicleTypes();
  return vehicles.find((v) => v.id === id);
}

export async function srcCityOf(id) {
  if (!id) return undefined;
  const provinces = await sourceCities();
  return provinces.map((p) => p.children).flat()
    .find((c) => c?.value === id);
}

export async function destCityOf(id) {
  if (!id) return undefined;
  const provinces = await destCities();
  return provinces.map((p) => p.children).flat()
    .find((c) => c.value === id);
}
