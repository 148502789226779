import useData from 'hooks/useData';
import { useEffect } from 'react';
import { vehicleOf } from 'services/base';

export default function VehicleTypeView({ vehicleType }) {
  const { data: vType, load: getVehicleType } = useData(vehicleOf);
  useEffect(() => getVehicleType(vehicleType), [vehicleType]);
  if (!vType) return <></>;
  return <>{vType.title}</>;
}
