import { useLocation } from 'react-router-dom';
import queryString from 'querystring';
import { Button } from 'antd';
import { useEffect } from 'react';
import OrderService from 'services/OrderService';

export default function PaymentResult() {
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const successful = qs.Status === 'OK';
  const authority = qs.Authority;
  useEffect(() => {
    if (successful === true && authority) {
      OrderService.finalizePayment(authority);
    }
  }, [successful, authority]);
  const returnBack = () => {
    if (window?.opener?.location?.reload) {
      window.opener.location.reload();
    }
    window.close();
  };
  return (
    <>
      {successful ? 'پرداخت انجام شد'
        : 'خطا در پرداخت'}
      <Button block type="primary" onClick={returnBack}>بازگشت</Button>
    </>
  );
}
