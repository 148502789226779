import {
  ConfigProvider, Spin,
} from 'antd';
// eslint-disable-next-line
import fa_IR from 'antd/lib/locale-provider/fa_IR';
import moment from 'jalali-moment';
import {
  Route, BrowserRouter as Router, Switch, useHistory, useLocation,
} from 'react-router-dom';
import AuthContext, { AuthProvider } from 'components/AuthContext';
import { useContext, useEffect } from 'react';
import AuthLayout from 'components/AuthLayout';
import DriverAppLayout from 'components/DriverAppLayout';
import { SearchContextProvider } from 'components/SearchContext';
import OrderDetail from './OrderDetail';
import RequestFreight from './RequestFreight';
import Login from './Login';
import Profile from './Profile';
import OfferReserve from './OfferReserve';
import PaymentResult from './PaymentResult';
import DriverVerify from './DriverVerify';

moment.locale('fa');

export default function DriverApp() {
  return (
    // eslint-disable-next-line
    <ConfigProvider direction="rtl" locale={fa_IR}>
      <Router>
        <AuthProvider>
          <DriverRoutes />
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
}

function DriverRoutes() {
  const { loggedIn, loading } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (!loading) {
      if (!loggedIn && location.pathname !== '/login') {
        history.push('/login');
      }
      if (loggedIn && location.pathname === '/login') {
        history.push('/');
      }
    }
  }, [loggedIn, loading, location.pathname, history]);
  if (loading) {
    return <Spin />;
  }

  return (
    <Switch>
      <Route path="/login">
        <AuthLayout>
          <Login />
        </AuthLayout>
      </Route>
      <Route path="/">
        <DriverAppLayout>
          <SearchContextProvider>
            <Switch>
              <Route path="/driver/verify">
                <DriverVerify />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/search">
                <RequestFreight />
              </Route>
              <Route
                path="/order/:id"
                render={({ match }) => <OrderDetail orderId={match.params.id} />}
              />
              <Route
                path="/reserve/:id"
                render={({ match }) => <OfferReserve orderId={match.params.id} />}
              />
              <Route
                path="/payment/result"
              >
                <PaymentResult />
              </Route>
              <Route path="/">
                <RequestFreight />
              </Route>
            </Switch>
          </SearchContextProvider>
        </DriverAppLayout>
      </Route>

    </Switch>
  );
}
