function SvgFreight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16.933 16.933"
      height="1em"
      width="1em"
      fill="currentColor"
    >
      <g transform="translate(0 -280.067)">
        <image
          width={133.35}
          height={162.454}
          preserveAspectRatio="none"
          x={-370.293}
          y={83.207}
          imageRendering="optimizeQuality"
        />
        <path d="M1.7 296.937a.496.496 0 01-.142-.16c-.046-.09-.048-.53-.042-8.27l.007-8.174.057-.076a.79.79 0 01.133-.133l.077-.057h13.367l.093.066c.183.13.17-.55.17 8.408 0 6.952-.006 8.168-.04 8.232a.652.652 0 01-.112.143l-.074.07-1.773.006-1.773.007-.094-.063c-.174-.116-.191-.188-.191-.8v-.545H5.566v.57c0 .53-.004.575-.058.662-.113.182-.034.175-1.966.175h-1.75zm3.094-1.166v-.455H2.282v.91h2.512zm9.853 0v-.455h-2.512v.91h2.512zm-9.788-.76a.447.447 0 01.156-.137c.106-.054.169-.055 3.455-.055 3.132 0 3.354.003 3.444.048a.446.446 0 01.152.137l.055.089h1.263l1.263.002V293.908H2.282V295.095H4.81zm.21-1.655v-.331H2.283v.662H5.07zm3.285 0v-.331H5.291v.662H8.354zm3.284 0v-.331H8.575v.662H11.638zm3.009 0v-.331h-2.788v.662h2.788zm0-1.532v-.427H2.282v.855h12.365zm0-5.823v-5.175l-1.65.007-1.648.007-.006 2.484-.006 2.484-2.871.007-2.87.007v-4.996H2.282v10.35h12.365zm-11.399 3.45v-.898l.89.007.89.007.007.89.007.89H3.248zm1.573 0v-.676H3.47v1.352h1.352zm1 .007l.007-.89h1.767l.007.89.007.89H5.813zm1.567-.007v-.676H6.036v1.352h1.352zm.966-6.514v-2.11H6.367V285.048h1.987zm2.229.007l-.007-2.104-1-.007-1.001-.007V285.049h2.014z" />
      </g>
    </svg>
  );
}

export default SvgFreight;
