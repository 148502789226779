import {
  CarOutlined,
  PlusOutlined, UploadOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Col, Row, Upload,
} from 'antd';
import DashboardCard from 'components/DashboardCard';
import { useHistory } from 'react-router-dom';
import { fileUploadRequest } from 'services/utils';

/* eslint-disable react/jsx-props-no-spreading */

const dashColLayout = {
  span: 24,
  md: 12,
  lg: 6,
};

export default function Home() {
  const history = useHistory();
  const gotoOrders = () => history.push('/orders/');
  const gotoFreight = () => history.push('/freight/step1');
  const onUploadFinish = () => {
    gotoOrders();
  };

  return (
    <>
      <Row gutter={8}>
        <Col {...dashColLayout}>
          <DashboardCard
            icon={<CarOutlined />}
            color="yellow"
            number="30"
            title="بار امروز"
          />
        </Col>
        <Col {...dashColLayout}>
          <DashboardCard
            color="gray"
            number="30"
            title="بار امروز"
          />
        </Col>
      </Row>
      <Card>
        <Upload
          name="file"
          action="/shipping/import"
          customRequest={fileUploadRequest}
          showUploadList={false}
          onChange={(info) => {
            if (info.file.status === 'done') {
              onUploadFinish();
            }
          }}
        >
          <Button
            type="primary"
            icon={<UploadOutlined />}
            block
          >
            ثبت بار فایلی
          </Button>
        </Upload>
        <Button
          onClick={gotoFreight}
          type="primary"
          icon={<PlusOutlined />}
        >
          ثبت بار تکی
        </Button>

      </Card>
      ,
    </>
  );
}
