import {
  Card, Descriptions, Spin, Typography,
} from 'antd';
import Currency from 'components/Currency';
import PaymentButton from 'components/PaymentButton';
import useData from 'hooks/useData';
import { useEffect } from 'react';
import OrderService from 'services/OrderService';

const { Paragraph, Title } = Typography;
export default function OfferReserve({ orderId }) {
  const { data: reserveInfo, load: getReserveInfo, loading } = useData(OrderService.reserveInfo);
  useEffect(() => {
    getReserveInfo(orderId);
  }, [orderId, getReserveInfo]);
  return (
    <Spin spinning={loading}>
      <Card title="رزرو بار">
        <Descriptions bordered>
          <Descriptions.Item
            label="کرایه کل"
            labelStyle={{ fontWeight: 'bold' }}
            style={{ fontWeight: 'bold' }}
          >
            <Currency value={reserveInfo?.totalPrice} />
          </Descriptions.Item>
          <Descriptions.Item label="بیمه">
            <Currency value={reserveInfo?.insurance} />
          </Descriptions.Item>
          <Descriptions.Item label="کمیسیون باربری">
            <Currency value={reserveInfo?.commissionFee} />
          </Descriptions.Item>
          <Descriptions.Item label="صافی راننده">
            <Currency value={reserveInfo?.driverPrice} />
          </Descriptions.Item>
        </Descriptions>
        <Title level={4}>نکات مهم</Title>
        <Paragraph>در صورت لغو کردن رزرو هزینه پرداختی بازگردانده نمی‌شود</Paragraph>
        <Paragraph>به توضیحات بار حتما توجه کنید</Paragraph>
        <PaymentButton disabled={!reserveInfo?.reservePrice}>
          پرداخت
          {' '}
          <Currency value={reserveInfo?.reservePrice} />
          {' '}
          و رزرو
        </PaymentButton>
      </Card>
    </Spin>
  );
}
