import { fetcher } from 'services/api';
import useSWR from 'swr';

export default function useAuthUser() {
  const { data: authUser, revalidate: refreshAuth, error } = useSWR('/users/me', fetcher, { shouldRetryOnError: false });
  const passwordResetRequired = authUser?.forcePasswordReset;
  const loading = !error && !authUser;
  const is401 = error?.response?.status === 401;
  const isAdmin = authUser?.roles.some((role) => role === 'ROLE_ADMIN');

  return {
    authUser: is401 ? null : authUser, isAdmin, refreshAuth, passwordResetRequired, loading,
  };
}
